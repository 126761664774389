export default defineComponent({
  props: {
    contentText: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'success'
    },
    outlined: {
      type: Boolean,
      default: true
    }
  }
});